import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Get } from "../../utils/REST";

const CaseStudies = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const getData = () => {
    setIsLoading(true);
    Get("case-studies", { populate: "*" })
      .then((res) => {
        console.log(res);
        setData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="max-w-6xl mx-auto py-12">
      <div className="md:px-20 px-4 text-center">
        <h2 className="md:text-4xl text-3xl font-semibold my-5">Case Studies</h2>
        <p className="px-10">
          Take a peak on how countless brands have been utilising our services
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 my-10 gap-7 px-4 md:px-0">
        {data.map((item) => (
          <div key={item.id} className="flex flex-col gap-5">
            <img
              src={item.image.url}
              alt="Case Study"
              className="w-full h-[30rem] object-cover rounded-xl"
            />
            <p className="font-semibold text-lg">{item.title}</p>
            <p>{item.descriptions.substring(0, 50)}</p>
            <p
              onClick={() => {
                navigate("/case-study", {
                  state: {
                    title: item.title,
                    text: item.descriptions,
                    image: item.image.url,
                  },
                });
              }}
              className="bg-gradient-to-r from-purple-600 via-blue-600 to-soft-red-500 cursor-pointer text-transparent bg-clip-text font-semibold"
            >
              Checkout Case Study
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CaseStudies;
